@charset "utf-8";



// Our variables
$base-font-family: Helvetica Neue, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;
$spacing-unit-lrg: 60px;
$spacing-unit-sml: 15px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #1d428a;
$brand-green:      #077821;
$brand-red:        #b8231f;

$grey-color:       #1D428A;
$grey-color-light: #2450A8;
$grey-color-dark:  #153065;

// Width of the content area
$content-width:    1200px;

$on-palm:          600px;
$on-laptop:        800px;



// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;
