/**
 * Fonts
 */
@font-face {
    font-family: "u1f400";
    src: url("/assets/u1f400.woff") format('woff');
}

/**
 * Site header
 */
.site-header {
    background-color: $brand-color;

    // Positioning context for the mobile navigation icon
    position: relative;
    padding: 50px 0;

    box-shadow: inset 0 -15px 15px -8px rgba(0, 0, 0, 0.4);

    img {
        max-height: 100px;
        float: left;
        padding-top: 2px;
    }

    .site-title {
        font-size: 36px;
        line-height: 56px;
        float: left;
        clear: both;

        &,
        &:visited {
            color: $background-color;
        }
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    background-color: $brand-color;
    padding: $spacing-unit 0;


    box-shadow: inset 0 15px 15px -8px rgba(0, 0, 0, 0.4);
}

.footer-heading {
    font-size: 18px;
    color: $background-color;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;

    a {
        color: $background-color;
    }
}

.footer-col-wrapper {
    font-size: 15px;
    color: $background-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 25px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    h2 {
        display: inline;
    }
    }

    > li:before {
        color:#1d428a;
        content:"\2022";
        font-size:1.5em;
        padding-right:.25em;
        position:relative;
        top:.1em;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    font-size: 24px;
}

/**
 * Post Layout
 */
.home {
  float: left;
  width: 80%;
}

.sidebar {
  float: right;
  width: 20%;
}

.affix {
  position: fixed;
  top: $spacing-unit;
}

@media screen and (max-width: 700px) {
  .home {
    width: 100%;
  }
  
  .sidebar {
    display: none;
  }
}

/**
 * Sidebar
 */
.scrollspy {
  ul {
    list-style: none;
  }

  li {
    padding: 0px $spacing-unit-sml;
    margin-left: 2px;
  }

  a:visited {
    color: $brand-color;
  }

  .active {
    border-left: 2px solid $brand-color;
    margin-left: 0;
  }
}
.affix-top {
    position: absolute;
}

/**
 * Posts
 */
.post-header {
    border-bottom: 2px solid $brand-color;
    margin-top: $spacing-unit;
    margin-bottom: $spacing-unit / 1.5;
}

.post-title {
    color: $brand-color;
    font-size: 42px;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit * 2;

    h2 {
        margin-top: 10px;

        clear: both;
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        clear: both;
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        clear: both;
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }

    .blue {
        color: $brand-color;
        border-bottom: 2px solid $brand-color;
    }

    .green {
        color: $brand-green;
        border-bottom: 2px solid $brand-green;
    }

    .red {
        color: $brand-red;
        border-bottom: 2px solid $brand-red;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    table + p {
        margin-top: 10px;
    }

    .padding td {
        padding: 6px;
    }
}

span.anchor-container {
    float: right;
    font-size: 1em;

    a {
        font-family: "u1f400";
    }
}
